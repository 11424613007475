<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      ภาวะความเจ็บปวดของร่างกาย
    </template>
    <div>
      การวิเคราะห์ข้อมูลภาวะความเจ็บปวดเป็นการวิเคราะห์ในรายละเอียดของความเจ็บปวดในด้านต่างๆ
      ของผู้สูงอายุ โดยแยกเปรียบเทียบความเจ็บปวดของร่างกายตาม ช่วงอายุ เพศ
      และความเจ็บปวดของอวัยวะต่างๆ โดยการวิเคราะห์ในส่วนนี้ จะเป็นการนำเสนอ
    </div>
    <div>
      1.ข้อมูลความเจ็บปวดของร่างกายกับความสามารถในการทำงานแยกตามช่วงอายุ
    </div>
    <div>
      2.ข้อมูลแสดงความเจ็บปวดของร่างกายกับความสามารถในการทำงานแยกตามช่วงอายุและเพศ
    </div>
    <div>3.ข้อมูลจำนวนผู้สูงอายุที่มีอาการปวดในจุดต่างๆ ของร่างกาย</div>
    <div>
      4.ข้อมูลแสดงจำนวนคนที่มีอาการปวดหลังแยกตามช่วงอายุ และสถานะการทำงาน
    </div>
  </Fieldset>
  <Fieldset :legend="images['pain_score']">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="() => (isModal = true)"
        />
      </div>
    </div>
    <img :src="plots['pain_score']" style="max-width: 100%" />
    <DividerWord :content="words['PAIN01']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงจำนวนผู้สูงอายุแยกตามจำนวนจุดของร่างกายที่มีความเจ็บปวดตามช่วงอายุ"
  >
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="() => (isModal = true)"
        />
      </div>
    </div>
    <div ref="chartHealthSum" :style="graphStyle"></div>
    <DividerWord :content="words['PAIN02']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงค่าเฉลี่ยความเจ็บปวดของร่างกายแยกตามช่วงอายุและเพศ
"
  >
    <div ref="chartPainScore" :style="graphStyle"></div>
    <DividerWord :content="words['PAIN03']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงจำนวนผู้สูงอายุที่มีอาการปวดตามจุดต่างๆ ของร่างกาย">
    <div ref="chartPainGroup" :style="graphStyle"></div>
    <DividerWord :content="words['PAIN04']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงอาการปวดของร่างกาย">
    <Chart type="polarArea" :data="chartDataBodyPain" />
    <DividerWord :content="words['PAIN05']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงจำนวนผู้สูงอายุที่มีอาการปวดหลังแยกตามช่วงอายุและสถานะการทำงาน"
  >
    <div ref="chartPainBack" :style="graphStyle"></div>
    <DividerWord :content="words['PAIN06']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงจำนวนผู้สูงอายุที่มีอาการปวดเข่าแยกตามช่วงอายุและสถานะการทำงาน"
  >
    <div ref="chartKnee" :style="graphStyle"></div>
    <DividerWord :content="words['PAIN07']" />
  </Fieldset>

  <ModalPainScore :is-open="isModal" @dimmer="() => (isModal = false)" />
  <MoveToTopPage />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import ModalPainScore from './ModalPainScore'
import MoveToTopPage from '../../components/MovetoTop'
import DividerWord from './DividerWord'

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      chartDataBodyPain: { labels: [], data: [] },
      chartDataPainFlag: { labels: [], data: [] },
      series: {
        pain: {}
      },
      plots: {
        pain_score: null
      },
      images: {
        pain_score:
          'กราฟแสดงระดับความเจ็บปวดทางด้านร่างกายกับความสามารถในการทำงาน'
      },
      isModal: false
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const {
          countPainCount,
          avgPainScore,
          sumPainBackFlag,
          sumPainFlag,
          sumPainBackKnee
        } = newVal
        this.stackColumn(countPainCount)
        this.clusterColumnPainScore(avgPainScore)
        this.piePainGroup(sumPainFlag)
        this.clusterColumnPainBack(sumPainBackFlag)
        this.clusterColumnKnee(sumPainBackKnee)
      },
      deep: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      //**************** get graph api*************** */
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'BODYPAIN',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data

      this.getGraphBarplot()
      this.pieCircle(data)

      //**************** get graph api*************** */
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    },
    pieCircle(data) {
      const { sumPain, sumPainFlag } = data

      console.log('sumPain ::== ' + JSON.stringify(sumPain))
      console.log('sumPainFlag ::== ' + JSON.stringify(sumPainFlag))

      const { back, elbow, foot, hand, hip, knee, neck, scapula, shoulder } =
        sumPain
      this.chartDataBodyPain = {
        labels: [
          'คอ',
          'ไหล่',
          'สะบัก',
          'ข้อศอก/แขน',
          'ข้อมือ/มือ/นิ้ว',
          'หลังส่วนเอว',
          'สะโพก/ต้นขา',
          'ข้อเข่า/น่อง',
          'ข้อเท้า/เท้า/นิ้ว'
        ],
        datasets: [
          {
            backgroundColor: [
              '#e91e63',
              '#f44336',
              '#9c27b0',
              '#26C6DA',
              '#7E57C2',
              '#FF0080',
              '#FFFF00',
              '#663399',
              '#FF9900',
              '#FF0000'
            ],
            label: 'My dataset',
            data: [neck, shoulder, scapula, elbow, hand, back, hip, knee, foot]
          }
        ]
      }
    },

    stackColumn(pain) {
      // Create chart instance
      const chart = am4core.create(this.$refs.chartHealthSum, am4charts.XYChart)

      const {
        count01,
        count02,
        count03,
        count11,
        count12,
        count13,
        count21,
        count22,
        count23,
        count31,
        count32,
        count33,
        count41,
        count42,
        count43,
        count51,
        count52,
        count53,
        count61,
        count62,
        count63,
        count71,
        count72,
        count73,
        count81,
        count82,
        count83,
        count91,
        count92,
        count93,
        count101,
        count102,
        count103
      } = pain

      // Add data
      chart.data = [
        {
          category: '0',
          value1: count01,
          value2: count02,
          value3: count03
        },
        {
          category: '1',
          value1: count11,
          value2: count12,
          value3: count13
        },
        {
          category: '2',
          value1: count21,
          value2: count22,
          value3: count23
        },
        {
          category: '3',
          value1: count31,
          value2: count32,
          value3: count33
        },
        {
          category: '4',
          value1: count41,
          value2: count42,
          value3: count43
        },
        {
          category: '5',
          value1: count51,
          value2: count52,
          value3: count53
        },
        {
          category: '6',
          value1: count61,
          value2: count62,
          value3: count63
        },
        {
          category: '7',
          value1: count71,
          value2: count72,
          value3: count73
        },
        {
          category: '8',
          value1: count81,
          value2: count82,
          value3: count83
        },
        {
          category: '9',
          value1: count91,
          value2: count92,
          value3: count93
        }
      ]

      chart.colors.list = [
        am4core.color('#A685E2'),
        am4core.color('#F38BA0'),
        am4core.color('#A7D0CD')
      ]

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.strictMinMax = true
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.title.text = 'จำนวนจุดบนร่างกายที่มีอาการปวด (จุด)'

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      //valueAxis.renderer.inside = true
      //valueAxis.renderer.labels.template.disabled = true
      valueAxis.min = 0
      valueAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'
      //valueAxis.renderer.minGridDistance = 20

      // Create series
      function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.name = name
        series.dataFields.valueY = field
        series.dataFields.categoryX = 'category'
        series.sequencedInterpolation = true

        // Make it stacked
        series.stacked = true

        // Configure columns
        series.columns.template.width = am4core.percent(70)
        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.text = '{valueY}'
        labelBullet.locationY = 0.5
        labelBullet.label.hideOversized = true

        return series
      }
      createSeries('value1', '60 - 65')
      createSeries('value2', '66 - 70')
      createSeries('value3', '> 70')

      // Legend
      chart.legend = new am4charts.Legend()
      chart.legend.labels.template.text = 'อายุ {name}[/] ปี'
    },
    clusterColumnPainScore(pain) {
      var chart = am4core.create(this.$refs.chartPainScore, am4charts.XYChart)
      chart.colors.list = [am4core.color('#54ccfc'), am4core.color('#f7a0d5')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'คะแนนความเจ็บปวดของร่างกาย (คะแนน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }
      const { avg10, avg11, avg20, avg21, avg30, avg31 } = pain
      chart.data = [
        {
          category: '60 - 65 ปี',
          first: avg11,
          second: avg10
        },
        {
          category: '66 - 70  ปี',
          first: avg21,
          second: avg20
        },
        {
          category: '> 70  ปี',
          first: avg31,
          second: avg30
        }
      ]

      createSeries('first', 'ชาย')
      createSeries('second', 'หญิง')
      //createSeries('third', '> 70')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },
    piePainGroup(pain) {
      const { back, elbow, foot, hand, hip, knee, neck, scapula, shoulder } =
        pain
      this.chartDataPainFlag = {
        // shoulder, scapula, elbow, hand, back, hip, knee, foot
        labels: [
          'pain_neck',
          'pain_shoulder',
          'pain_scapula',
          'pain_elbow',
          'pain_hand',
          'pain_back',
          'pain_hip',
          'pain_knee',
          'pain_foot'
        ]
      }

      // Create chart instance
      const chart = am4core.create(
        this.$refs.chartPainGroup,
        am4charts.PieChart
      )

      // Add data
      chart.data = [
        {
          label: 'คอ',
          value: neck
        },
        {
          label: 'ไหล่',
          value: shoulder
        },
        {
          label: 'สะบัก',
          value: scapula
        },
        {
          label: 'ข้อศอก/แขน',
          value: elbow
        },
        {
          label: 'ข้อมือ/มือ/นิ้ว',
          value: hand
        },
        {
          label: 'หลังส่วนเอว',
          value: back
        },
        {
          label: 'สะโพก/ต้นขา',
          value: hip
        },
        {
          label: 'ข้อเข่า/น่อง',
          value: knee
        },
        {
          label: 'ข้อเท้า/เท้า/นิ้ว',
          value: foot
        }
      ]
      chart.legend = new am4charts.Legend()
      chart.legend.valueLabels.template.disabled = true

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries())
      pieSeries.colors.step = 2
      pieSeries.dataFields.value = 'value'
      pieSeries.dataFields.category = 'label'
      pieSeries.slices.template.stroke = am4core.color('#fff')
      pieSeries.slices.template.strokeWidth = 2
      pieSeries.slices.template.strokeOpacity = 1
      pieSeries.labels.template.text = '{category}: {value.value}'
      pieSeries.slices.template.tooltipText = '{category}: {value.value}'
      //chart.legend.valueLabels.template.text = '{value.value}'

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1
      pieSeries.hiddenState.properties.endAngle = -90
      pieSeries.hiddenState.properties.startAngle = -90
    },
    clusterColumnPainBack(pain) {
      var chart = am4core.create(this.$refs.chartPainBack, am4charts.XYChart)
      chart.colors.list = [am4core.color('#a6d0cc'), am4core.color('#f28ba0')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }
      const { sum11, sum12, sum21, sum22, sum31, sum32 } = pain
      chart.data = [
        {
          category: '60 - 65 ปี',
          first: sum11,
          second: sum12
        },
        {
          category: '66 - 70  ปี',
          first: sum21,
          second: sum22
        },
        {
          category: '> 70  ปี',
          first: sum31,
          second: sum32
        }
      ]

      createSeries('first', 'ประกอบอาชีพ')
      createSeries('second', 'ไม่ได้ประกอบอาชีพ')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },

    clusterColumnKnee(pain) {
      var chart = am4core.create(this.$refs.chartKnee, am4charts.XYChart)
      chart.colors.list = [am4core.color('#a6d0cc'), am4core.color('#f28ba0')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }
      const { sum11, sum12, sum21, sum22, sum31, sum32 } = pain
      chart.data = [
        {
          category: '60 - 65  ปี',
          first: sum11,
          second: sum12
        },
        {
          category: '66 - 70  ปี',
          first: sum21,
          second: sum22
        },
        {
          category: '> 70  ปี',
          first: sum31,
          second: sum32
        }
      ]

      createSeries('first', 'ประกอบอาชีพ')
      createSeries('second', 'ไม่ได้ประกอบอาชีพ')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    }
  },
  components: {
    ModalPainScore,
    MoveToTopPage,
    DividerWord
  }
}
</script>

<style>
</style>