<template>
  <Dialog v-model:visible="isOpen" :closable="false" :style="{ width: '35vw' }">
    <template #header>
      <h3>
        กราฟแสดงจำนวนผู้สูงอายุแยกตามจำนวนจุดของร่างกายที่มีความเจ็บปวดตามช่วงอายุ
      </h3>
    </template>
    <p>1. คอ</p>
    <p>2. ไหล่</p>
    <p>3. สะบัก</p>
    <p>4. ข้อศอก/แขน</p>
    <p>5. ข้อมือ/มือ/นิ้ว</p>
    <p>6. หลังส่วนเอว</p>
    <p>7. สะโพก/ต้นขา</p>
    <p>8. ข้อเข่า/น่อง</p>
    <p>9. ข้อเท้า/เท้า/นิ้ว</p>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => $emit('dimmer')"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalPainScore',
  props: ['is-open']
}
</script>

<style>
</style>